import React, { Component } from 'react';

class MuteIcon extends Component {
  render() {
    return (
      <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg"><path d="M14.38 13.24v6.983c0 .386-.05.757-.296 1.076-.536.69-1.433.817-2.12.261-1.062-.86-2.105-1.748-3.156-2.624-1.03-.857-2.054-1.718-3.086-2.57-.15-.124-.224-.246-.223-.45.008-1.798.008-3.597 0-5.395a.498.498 0 0 1 .203-.429 2277.23 2277.23 0 0 0 6.052-5.048c.362-.303.74-.55 1.24-.496.864.093 1.38.691 1.385 1.618.007 1.49.002 2.977.002 4.466v2.608zm11.87-.208c-.266 4.582-2.263 7.762-6.2 9.63-.782.371-1.667.533-2.515.754-.456.118-.848-.145-.922-.539-.086-.458.158-.816.648-.905 1.477-.268 2.834-.81 4.022-1.745 1.911-1.505 3.077-3.462 3.351-5.874.395-3.488-.871-6.3-3.691-8.388-1.013-.75-2.173-1.212-3.421-1.439-.107-.019-.216-.038-.322-.066-.442-.118-.674-.48-.582-.902.082-.377.444-.628.871-.542.567.115 1.138.233 1.68.426 3.477 1.24 5.723 3.653 6.694 7.21.233.85.288 1.747.387 2.38zm-4.48-.027c-.003 2.81-1.712 5.102-4.197 5.857a.736.736 0 0 1-.912-.419.715.715 0 0 1 .352-.937c.195-.096.41-.151.611-.239 1.709-.746 2.719-2.376 2.654-4.279-.06-1.734-1.227-3.296-2.921-3.909-.113-.041-.229-.075-.337-.125a.746.746 0 0 1-.393-.904c.114-.342.47-.569.826-.487.947.22 1.753.713 2.453 1.373 1.232 1.164 1.835 2.608 1.864 4.069zM0 15.965v-5.5h3.5v5.5H0z" fill="#FFF" fillRule="evenodd"/></svg>
    );
  }
};

export default MuteIcon;
