import React, { Component } from 'react';

class UnmuteIcon extends Component {
  render() {
    return (
      <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg"><path d="M7.631 17.954c-.635-.53-1.27-1.06-1.91-1.588-.15-.124-.223-.246-.222-.45.008-1.798.008-3.597 0-5.395a.498.498 0 0 1 .203-.429 2277.23 2277.23 0 0 0 6.052-5.048c.362-.303.74-.55 1.24-.496.864.093 1.38.691 1.385 1.618.007 1.49.002 2.977.002 4.466v.573l2.413-2.413a.748.748 0 0 1-.168-.742c.115-.342.471-.569.826-.487.152.035.3.077.445.126l2.243-2.243a8.41 8.41 0 0 0-2.618-.92c-.107-.019-.216-.038-.322-.066-.442-.118-.674-.48-.582-.902.082-.377.444-.628.871-.542.567.115 1.138.233 1.68.426.733.262 1.411.575 2.034.94l2.59-2.59a1 1 0 0 1 1.414 1.415L3.701 24.713a1 1 0 0 1-1.414-1.414l5.344-5.345zm6.75-.506v2.775c0 .386-.05.757-.297 1.076-.536.69-1.433.817-2.12.261-.312-.253-.622-.508-.932-.764l3.349-3.348zm8.979-8.98l1.062-1.061c.628.947 1.11 2.03 1.441 3.245.233.85.387 1.348.387 2.38-.266 4.582-2.263 7.762-6.2 9.63-.782.371-1.667.533-2.515.754-.456.118-.848-.145-.922-.539-.086-.458.158-.816.648-.905 1.477-.268 2.834-.81 4.022-1.745 1.911-1.505 3.077-3.462 3.351-5.874.254-2.246-.18-4.212-1.274-5.884zm-3.31 3.31l1.136-1.136c.377.753.568 1.555.584 2.363-.003 2.81-1.712 5.102-4.197 5.857a.736.736 0 0 1-.912-.419.715.715 0 0 1 .352-.937c.195-.096.41-.151.611-.239 1.709-.746 2.719-2.376 2.654-4.279a4.114 4.114 0 0 0-.228-1.21zM0 15.965v-5.5h3.5v5.5H0z" fill="#FFF" fillRule="evenodd"/></svg>
    );
  }
};

export default UnmuteIcon;
